import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import Airtel from "../assets/Airtel.png";
import Glo from "../assets/Glo.jpeg";
import MTN from "../assets/MTN.jpeg";
import Nmobile from "../assets/9mobile.jpeg";
import { Tab } from "@headlessui/react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  buyData,
  billerEnquiry,
  billerGroupEnquiry,
  billerGroup,
  verifyTransactionPinCode,
  getWalletBalance,
  getTransactions,
  delgroDataPurchase,
} from "../store/actions";
import { setSelectBiller } from "../store/slices/modalSlice";
// import { Store } from "react-notifications-component";
// import { notification } from "../core/helpers";
import toast from "react-hot-toast";
import { authService } from "../services/authService";
import IsLoadingFour from "./IsloadingFour";
import { CircularProgress } from "@mui/material";

function BuyDataPopover({ onClose }) {
  const dispatch = useDispatch();
  const [fetchedDataPlans, setFetchedDataPlans] = useState([]);
  const [updatedDataPlans, setUpdatedDataPlans] = useState([]);
  const [selectedBillerGroupEnq, setSelectedBillerGroupEnq] = useState();
  const [fetchedBillerGroups, setFetchedBillergroups] = useState([]);
  const [selectedSlug, setSelectedSlug] = useState("");
  const [selectedAmount, setSelectedAmount] = useState("");
  const [selectedBiller, setSelectedBiller] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();
  const customerId = authService.getCurrentCustomerId();
  const [otp, setOtp] = useState(new Array(4).fill(""));

  const { billerEq, biller, billerGrpEnq } = useSelector((state) => state.vas);
  const { selectBiller } = useSelector((state) => state.modal);
  const { walletBalance } = useSelector((state) => state.user);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  // const selectedBiller = 88;
  useEffect(() => {
    if (!biller) {
      dispatch(billerGroup());
    }
  }, [biller, dispatch]);

  useEffect(() => {
    if (biller) {
      const filteredBiller =
        biller &&
        biller?.length &&
        biller?.filter((bill) => bill.slug === "AIRTIME_AND_DATA");
      setFetchedBillergroups(filteredBiller);
    }
  }, [biller, dispatch]);

  useEffect(() => {
    if (!billerEq && selectedBiller) {
      dispatch(billerEnquiry(selectedBiller));
    }

    if (billerEq) setFetchedDataPlans(billerEq);
  }, [billerEq, selectedBiller, dispatch]);

  useEffect(() => {
    if (fetchedBillerGroups && fetchedBillerGroups?.length) {
      dispatch(billerGroupEnquiry(fetchedBillerGroups[0]?.id));
    }
  }, [fetchedBillerGroups, dispatch]);

  useEffect(() => {
    if (fetchedDataPlans && fetchedDataPlans?.length) {
      setUpdatedDataPlans(fetchedDataPlans);
    }
  }, [fetchedDataPlans, dispatch]);

  useEffect(() => {
    if (billerGrpEnq && billerGrpEnq?.length) {
      setSelectedBillerGroupEnq(billerGrpEnq);
    }
  }, [billerGrpEnq, dispatch]);

  useEffect(() => {
    if (selectedBillerGroupEnq && selectedBillerGroupEnq.length) {
      const filteredBillerGroup =
        selectedBillerGroupEnq &&
        selectedBillerGroupEnq?.length &&
        selectedBillerGroupEnq?.filter(
          (bill) => bill.slug === "AIRTEL_NIGERIA"
        );
      // setFetchedBillergroups(filteredBiller);
      setSelectedBiller(filteredBillerGroup[0]?.id);
    }
  }, [selectedBillerGroupEnq, dispatch]);

  const handleDataFetch = (id, biller) => {
    dispatch(setSelectBiller(biller));
    dispatch(billerEnquiry(id));
  };

  const handleDataSlug = (event) => {
    const slug = event.target.value.split("/")[0];
    const amount = event.target.value.split("/")[1];

    setSelectedSlug(slug);
    setSelectedAmount(amount);
  };

  const handleWalletUpdate = () => {
    const data = {
      sql_ref_no: "31",
      user_id: userId.toString(),
      customer_id: customerId.toString(),
      verification_code,
      option: "1",
    };

    dispatch(getWalletBalance(data))
      .unwrap()
      .then(() => {
        setIsLoading(false);
        const id = customerId?.toString();
        return dispatch(getTransactions(id));
      })
      .catch((err) => {
        return err;
      });
  };

  const handleSubmit = (event) => {
    try {
      event.preventDefault();

      const amount = selectedAmount;

      if (
        !walletBalance?.available_balance ||
        walletBalance?.available_balance < amount
      ) {
        return toast.error("You have insufficient funds..");
      }

      if (!selectBiller) return;

      setIsLoading(true);

      const verifyData = {
        sql_ref_no: "41",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        verification_code,
        pin_number: otp.join(""),
        option: "1",
      };

      dispatch(verifyTransactionPinCode(verifyData))
        .unwrap()
        .then((res) => {
          if (res?.status === 403) {
            setIsLoading(false);
            return toast.error("Pin Incorrect...");
          }

          const toastId = toast.loading("Vending data....");

          let data = {};

          switch (selectBiller) {
            case "AIRTEL_NIGERIA":
              data = {
                user_id: userId?.toString(),
                customer_id: customerId?.toString(),
                transaction_type_id: "4",
                mobile_number: phone,
                amount,
                remark: "Buy Data",
                verification_code,
                transaction_pin: otp?.join(""),
                packageSlug: selectedSlug,
                network: selectBiller,
              };

              return dispatch(delgroDataPurchase(data))
                .unwrap()
                .then((res) => {
                  if (res && res?.status === 403) {
                    setIsLoading(false);
                    return toast.error(res.response.error, {
                      id: toastId,
                    });
                  }

                  onClose();
                  setIsLoading(false);
                  toast.success(res[0]?.Message_Text, { id: toastId });
                  dispatch(setSelectBiller(""));
                  return handleWalletUpdate();
                })
                .catch((err) => {
                  setIsLoading(false);
                  toast.error(err?.message);
                });
            case "MTN_NIGERIA":
              data = {
                user_id: userId?.toString(),
                customer_id: customerId?.toString(),
                transaction_type_id: "4",
                mobile_number: phone,
                amount,
                remark: "Buy Data",
                verification_code,
                transaction_pin: otp?.join(""),
                packageSlug: selectedSlug,
                network: selectBiller,
              };
              return dispatch(delgroDataPurchase(data))
                .unwrap()
                .then((res) => {
                  if (res && res?.status === 403) {
                    setIsLoading(false);
                    return toast.error(res.response.error, {
                      id: toastId,
                    });
                  }

                  onClose();
                  setIsLoading(false);
                  toast.success(res[0]?.Message_Text, { id: toastId });
                  dispatch(setSelectBiller(""));
                  return handleWalletUpdate();
                })
                .catch((err) => {
                  setIsLoading(false);
                  toast.error("Vend Data failed");
                  return err;
                });
            case "GLO_NIGERIA":
              data = {
                user_id: userId?.toString(),
                customer_id: customerId?.toString(),
                transaction_type_id: "4",
                mobile_number: phone,
                amount,
                remark: "Buy Data",
                verification_code,
                transaction_pin: otp?.join(""),
                packageSlug: selectedSlug,
                network: selectBiller,
              };

              return dispatch(delgroDataPurchase(data))
                .unwrap()
                .then((res) => {
                  if (res && res?.status === 403) {
                    setIsLoading(false);
                    return toast.error(res.response.error, {
                      id: toastId,
                    });
                  }

                  onClose();
                  setIsLoading(false);
                  toast.success(res[0]?.Message_Text, { id: toastId });
                  dispatch(setSelectBiller(""));
                  return handleWalletUpdate();
                })
                .catch((err) => {
                  setIsLoading(false);
                  toast.error("Vend Data failed");
                  return err;
                });
            case "9MOBILE_NIGERIA":
              data = {
                user_id: userId?.toString(),
                customer_id: customerId?.toString(),
                transaction_type_id: "4",
                mobile_number: phone,
                amount,
                remark: "Buy Data",
                verification_code,
                transaction_pin: otp?.join(""),
                packageSlug: selectedSlug,
                network: selectBiller,
              };

              return dispatch(delgroDataPurchase(data))
                .unwrap()
                .then((res) => {
                  if (res && res?.status === 403) {
                    setIsLoading(false);
                    return toast.error(res.response.error, {
                      id: toastId,
                    });
                  }

                  onClose();
                  setIsLoading(false);
                  toast.success(res[0]?.Message_Text, { id: toastId });
                  dispatch(setSelectBiller(""));
                  return handleWalletUpdate();
                })
                .catch((err) => {
                  setIsLoading(false);
                  toast.error("Vend Data failed");
                  return err;
                });
            default:
              return;
          }
        })
        .catch((err) => {
          setIsLoading(false);
          return toast.error("Pin Incorrect...");
        });
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="mx-2 w-full max-w-[420px] rounded-2xl bg-white px-3 py-3 text-gray-500 xsm:mx-4 xsm:px-6 xsm:py-4">
      <div className="flex items-center justify-between">
        <div className="text-lg font-bold text-gravel-500">Buy Data</div>
        <button
          onClick={() => onClose()}
          className="rounded-full p-1 hover:bg-gray-200"
        >
          <IoClose className="h-5 w-5 text-gravel-500" />
        </button>
      </div>
      <div className="mt-6 flex justify-start text-sm font-semibold text-gravel-500">
        Select Network
      </div>
      <div className="mt-4">
        <Tab.Group>
          <Tab.List className="flex items-center space-x-2 sm:space-x-3">
            {selectedBillerGroupEnq &&
              selectedBillerGroupEnq?.length &&
              selectedBillerGroupEnq?.map((sbge) => {
                if (sbge?.slug === "AIRTEL_NIGERIA") {
                  return (
                    <div key={sbge?.id}>
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          /* Use the `selected` state to conditionally style the selected tab. */
                          <button
                            className={
                              selectBiller === sbge?.slug
                                ? "rounded-md ring-1 ring-olivine-500"
                                : "ring-0"
                            }
                            onClick={() =>
                              handleDataFetch(sbge?.id, sbge?.slug)
                            }
                          >
                            <div className="h-[68px] w-14 cursor-pointer space-y-1 rounded-md border border-gray-200 pt-5 xsm:h-[75px] xsm:w-[71px] sm:h-[82px] sm:w-20">
                              <div className="flex justify-center">
                                <img
                                  src={Airtel}
                                  className="aspect-auto h-7 w-7 object-cover xsm:h-8 xsm:w-8 sm:h-9 sm:w-9"
                                  alt=""
                                />
                              </div>
                              <div className="flex justify-center text-[8.5px] sm:text-[11px]">
                                Airtel Nigeria
                              </div>
                            </div>
                          </button>
                        )}
                      </Tab>
                    </div>
                  );
                }
                return null;
              })}
            {selectedBillerGroupEnq &&
              selectedBillerGroupEnq?.length &&
              selectedBillerGroupEnq?.map((sbge) => {
                if (sbge?.slug === "GLO_NIGERIA") {
                  return (
                    <div key={sbge?.id}>
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          /* Use the `selected` state to conditionally style the selected tab. */
                          <button
                            className={
                              selectBiller === sbge?.slug
                                ? "rounded-md ring-1 ring-olivine-500"
                                : "ring-0"
                            }
                            onClick={() =>
                              handleDataFetch(sbge?.id, sbge?.slug)
                            }
                          >
                            {" "}
                            <div className="h-[68px] w-14 cursor-pointer space-y-1 rounded-md border border-gray-200 pt-5 xsm:h-[75px] xsm:w-[71px] sm:h-[82px] sm:w-20">
                              <div className="flex justify-center">
                                <img
                                  src={Glo}
                                  className="aspect-auto h-7 w-7 object-cover xsm:h-8 xsm:w-8 sm:h-9 sm:w-9"
                                  alt=""
                                />
                              </div>
                              <div className="flex justify-center text-[8.5px] sm:text-[11px]">
                                GLO Direct
                              </div>
                            </div>
                          </button>
                        )}
                      </Tab>
                    </div>
                  );
                }
                return null;
              })}

            {selectedBillerGroupEnq &&
              selectedBillerGroupEnq?.length &&
              selectedBillerGroupEnq?.map((sbge) => {
                if (sbge?.slug === "MTN_NIGERIA") {
                  return (
                    <div key={sbge?.id}>
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          /* Use the `selected` state to conditionally style the selected tab. */
                          <button
                            className={
                              selectBiller === sbge?.slug
                                ? "rounded-md ring-1 ring-olivine-500"
                                : "ring-0"
                            }
                            onClick={() =>
                              handleDataFetch(sbge?.id, sbge?.slug)
                            }
                          >
                            {" "}
                            <div className="h-[68px] w-14 cursor-pointer space-y-1 rounded-md border border-gray-200 pt-5 xsm:h-[75px] xsm:w-[71px] sm:h-[82px] sm:w-20">
                              <div className="flex justify-center">
                                <img
                                  src={MTN}
                                  className="aspect-auto h-7 w-7 object-cover xsm:h-8 xsm:w-8 sm:h-9 sm:w-9"
                                  alt=""
                                />
                              </div>
                              <div className="flex justify-center text-[8.5px] sm:text-[11px]">
                                MTN
                              </div>
                            </div>
                          </button>
                        )}
                      </Tab>
                    </div>
                  );
                }
                return null;
              })}

            {selectedBillerGroupEnq &&
              selectedBillerGroupEnq?.length &&
              selectedBillerGroupEnq?.map((sbge) => {
                if (sbge?.slug === "9MOBILE_NIGERIA") {
                  return (
                    <div key={sbge?.id}>
                      <Tab as={Fragment}>
                        {({ selected }) => (
                          /* Use the `selected` state to conditionally style the selected tab. */
                          <button
                            className={
                              selectBiller === sbge?.slug
                                ? "rounded-md ring-1 ring-olivine-500"
                                : "ring-0"
                            }
                            onClick={() =>
                              handleDataFetch(sbge?.id, sbge?.slug)
                            }
                          >
                            {" "}
                            <div className="h-[68px] w-14 cursor-pointer space-y-1 rounded-md border border-gray-200 pt-5 xsm:h-[75px] xsm:w-[71px] sm:h-[82px] sm:w-20">
                              <div className="flex justify-center">
                                <img
                                  src={Nmobile}
                                  className="aspect-auto h-7 w-7 object-cover xsm:h-8 xsm:w-8 sm:h-9 sm:w-9"
                                  alt=""
                                />
                              </div>
                              <div className="flex justify-center text-[8.5px] sm:text-[11px]">
                                9Mobile VTU
                              </div>
                            </div>
                          </button>
                        )}
                      </Tab>
                    </div>
                  );
                }
                return null;
              })}

            {!selectedBillerGroupEnq?.length && (
              <p className="mt-4 text-xs text-gray-600">
                {
                  "This service is temporarily unavailable, please try again later"
                }
              </p>
            )}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <form>
                <div className="mt-4">
                  <label
                    htmlFor="phoneNumber"
                    className="ml-2 text-xs font-semibold"
                  >
                    Mobile number
                  </label>
                  <div className="relative mt-1 flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <input
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        type="number"
                        placeholder="08012345678"
                        className="block h-10 w-full rounded-md border-gray-300 bg-white pl-4 pr-10 text-gravel-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <label htmlFor="title" className="ml-2 text-xs font-semibold">
                    Choose Product
                  </label>
                  <div className="relative mt-1 flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <select
                        onChange={handleDataSlug}
                        type="text"
                        className="block h-10 w-full cursor-pointer rounded-md border-gray-300 bg-white pl-4 pr-10 text-gray-500 placeholder:text-sm optional:text-sm optional:font-semibold focus:border-olivine-500 focus:ring-olivine-500"
                      >
                        <optgroup className="rounded-lg bg-olivine-100">
                          <option>Select Plan</option>
                          {updatedDataPlans && updatedDataPlans.length ? (
                            updatedDataPlans.map((data) => (
                              <option
                                value={`${data.slug}/${data?.amount}`}
                                key={data.id}
                              >
                                {`${data.name} - ${`₦ ${Number(
                                  data?.amount
                                ).toLocaleString()}`}`}
                              </option>
                            ))
                          ) : (
                            <CircularProgress size={20} color="inherit" />
                          )}
                        </optgroup>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
              {phone?.length === 11 && selectedAmount ? (
                <div>
                  <div className="text-md mt-4 text-center font-semibold text-gray-600">
                    Transaction PIN
                  </div>
                  <div className="relative mt-2 flex items-center justify-center space-x-2 rounded-md">
                    {otp.map((data, index) => {
                      return (
                        <input
                          type="text"
                          maxLength={1}
                          className="block h-10 w-10 rounded-md border-gray-400 bg-white text-center text-lg font-semibold focus:border-olivine-500 focus:ring-olivine-500"
                          name="opt"
                          key={index}
                          value={data}
                          onChange={(e) => handleChange(e.target, index)}
                          onFocus={(e) => e.target.select()}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : null}
              <div className="mb-2 mt-6">
                <div className="flex justify-between text-center tracking-widest transition-all duration-300">
                  <button
                    className="rounded-md border-[1px] border-olivine-400 px-8 py-3 text-xs text-olivine-500 transition duration-300 hover:ring-1 hover:ring-olivine-600 sm:text-sm"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    CANCEL
                  </button>
                  {!isLoading ? (
                    <button
                      disabled={
                        phone?.length === 11 &&
                        selectedAmount &&
                        otp?.join("")?.length === 4
                          ? false
                          : true
                      }
                      onClick={(e) => handleSubmit(e)}
                      className="rounded-md bg-olivine-500 px-8 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
                    >
                      BUY DATA
                    </button>
                  ) : (
                    <button
                      disabled={true}
                      className="cursor-not-allowed rounded-md bg-olivine-500 px-8 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
                    >
                      <IsLoadingFour />
                    </button>
                  )}
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <form>
                <div className="mt-4">
                  <label
                    htmlFor="phoneNumber"
                    className="ml-2 text-xs font-semibold"
                  >
                    Mobile number
                  </label>
                  <div className="relative mt-1 flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <input
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        type="number"
                        placeholder="08012345678"
                        className="block h-10 w-full rounded-md border-gray-300 bg-white pl-4 pr-10 text-gravel-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <label htmlFor="title" className="ml-2 text-xs font-semibold">
                    Choose Product
                  </label>
                  <div className="relative mt-1 flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <select
                        onChange={handleDataSlug}
                        type="text"
                        className="block h-10 w-full cursor-pointer rounded-md border-gray-300 bg-white pl-4 pr-10 text-gray-500 placeholder:text-sm optional:text-sm optional:font-semibold focus:border-olivine-500 focus:ring-olivine-500"
                      >
                        <optgroup className="rounded-lg bg-olivine-100">
                          <option>Select Plan</option>
                          {updatedDataPlans && updatedDataPlans.length ? (
                            updatedDataPlans.map((data) => (
                              <option
                                value={`${data.slug}/${data?.amount}`}
                                key={data.id}
                              >
                                {`${data.name} - ${`₦ ${Number(
                                  data?.amount
                                ).toLocaleString()}`}`}
                              </option>
                            ))
                          ) : (
                            <CircularProgress size={20} color="inherit" />
                          )}
                        </optgroup>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
              {phone?.length === 11 && selectedAmount ? (
                <div>
                  <div className="text-md mt-4 text-center font-semibold text-gray-600">
                    Transaction PIN
                  </div>
                  <div className="relative mt-2 flex items-center justify-center space-x-2 rounded-md">
                    {otp.map((data, index) => {
                      return (
                        <input
                          type="text"
                          maxLength={1}
                          className="block h-10 w-10 rounded-md border-gray-400 bg-white text-center text-lg font-semibold focus:border-olivine-500 focus:ring-olivine-500"
                          name="opt"
                          key={index}
                          value={data}
                          onChange={(e) => handleChange(e.target, index)}
                          onFocus={(e) => e.target.select()}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : null}
              <div className="mb-2 mt-6">
                <div className="flex justify-between text-center tracking-widest transition-all duration-300">
                  <button
                    className="rounded-md border-[1px] border-olivine-400 px-8 py-3 text-xs text-olivine-500 transition duration-300 hover:ring-1 hover:ring-olivine-600 sm:text-sm"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    CANCEL
                  </button>
                  {!isLoading ? (
                    <button
                      disabled={
                        phone?.length === 11 &&
                        selectedAmount &&
                        otp?.join("")?.length === 4
                          ? false
                          : true
                      }
                      onClick={(e) => handleSubmit(e)}
                      className="rounded-md bg-olivine-500 px-8 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
                    >
                      BUY DATA
                    </button>
                  ) : (
                    <button
                      disabled={true}
                      className="cursor-not-allowed rounded-md bg-olivine-500 px-8 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
                    >
                      <IsLoadingFour />
                    </button>
                  )}
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <form>
                <div className="mt-4">
                  <label
                    htmlFor="phoneNumber"
                    className="ml-2 text-xs font-semibold"
                  >
                    Mobile number
                  </label>
                  <div className="relative mt-1 flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <input
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        type="number"
                        placeholder="08012345678"
                        className="block h-10 w-full rounded-md border-gray-300 bg-white pl-4 pr-10 text-gravel-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <label htmlFor="title" className="ml-2 text-xs font-semibold">
                    Choose Product
                  </label>
                  <div className="relative mt-1 flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <select
                        onChange={handleDataSlug}
                        type="text"
                        className="block h-10 w-full cursor-pointer rounded-md border-gray-300 bg-white pl-4 pr-10 text-gray-500 placeholder:text-sm optional:text-sm optional:font-semibold focus:border-olivine-500 focus:ring-olivine-500"
                      >
                        <optgroup className="rounded-lg bg-olivine-100">
                          <option>Select Plan</option>
                          {updatedDataPlans && updatedDataPlans.length ? (
                            updatedDataPlans.map((data) => (
                              <option
                                value={`${data.slug}/${data?.amount}`}
                                key={data.id}
                              >
                                {`${data.name} - ${`₦ ${Number(
                                  data?.amount
                                ).toLocaleString()}`}`}
                              </option>
                            ))
                          ) : (
                            <CircularProgress size={20} color="inherit" />
                          )}
                        </optgroup>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
              {phone?.length === 11 && selectedAmount ? (
                <div>
                  <div className="text-md mt-4 text-center font-semibold text-gray-600">
                    Transaction PIN
                  </div>
                  <div className="relative mt-2 flex items-center justify-center space-x-2 rounded-md">
                    {otp.map((data, index) => {
                      return (
                        <input
                          type="text"
                          maxLength={1}
                          className="block h-10 w-10 rounded-md border-gray-400 bg-white text-center text-lg font-semibold focus:border-olivine-500 focus:ring-olivine-500"
                          name="opt"
                          key={index}
                          value={data}
                          onChange={(e) => handleChange(e.target, index)}
                          onFocus={(e) => e.target.select()}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : null}
              <div className="mb-2 mt-6">
                <div className="flex justify-between text-center tracking-widest transition-all duration-300">
                  <button
                    className="rounded-md border-[1px] border-olivine-400 px-8 py-3 text-xs text-olivine-500 transition duration-300 hover:ring-1 hover:ring-olivine-600 sm:text-sm"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    CANCEL
                  </button>
                  {!isLoading ? (
                    <button
                      disabled={
                        phone?.length === 11 &&
                        selectedAmount &&
                        otp?.join("")?.length === 4
                          ? false
                          : true
                      }
                      onClick={(e) => handleSubmit(e)}
                      className="rounded-md bg-olivine-500 px-8 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
                    >
                      BUY DATA
                    </button>
                  ) : (
                    <button
                      disabled={true}
                      className="cursor-not-allowed rounded-md bg-olivine-500 px-8 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
                    >
                      <IsLoadingFour />
                    </button>
                  )}
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <form>
                <div className="mt-4">
                  <label
                    htmlFor="phoneNumber"
                    className="ml-2 text-xs font-semibold"
                  >
                    Mobile number
                  </label>
                  <div className="relative mt-1 flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <input
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        type="number"
                        placeholder="08012345678"
                        className="block h-10 w-full rounded-md border-gray-300 bg-white pl-4 pr-10 text-gravel-600 placeholder:text-sm placeholder:text-gray-400 focus:border-olivine-500 focus:ring-olivine-500"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <label htmlFor="title" className="ml-2 text-xs font-semibold">
                    Choose Product
                  </label>
                  <div className="relative mt-1 flex items-center justify-end rounded-md">
                    <div className="flex-grow">
                      <select
                        onChange={handleDataSlug}
                        type="text"
                        className="block h-10 w-full cursor-pointer rounded-md border-gray-300 bg-white pl-4 pr-10 text-gray-500 placeholder:text-sm optional:text-sm optional:font-semibold focus:border-olivine-500 focus:ring-olivine-500"
                      >
                        <optgroup className="rounded-lg bg-olivine-100">
                          <option>Select Plan</option>
                          {updatedDataPlans && updatedDataPlans.length ? (
                            updatedDataPlans.map((data) => (
                              <option
                                value={`${data.slug}/${data?.amount}`}
                                key={data.id}
                              >
                                {`${data.name} - ${`₦ ${Number(
                                  data?.amount
                                ).toLocaleString()}`}`}
                              </option>
                            ))
                          ) : (
                            <CircularProgress size={20} color="inherit" />
                          )}
                        </optgroup>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
              {phone?.length === 11 && selectedAmount ? (
                <div>
                  <div className="text-md mt-4 text-center font-semibold text-gray-600">
                    Transaction PIN
                  </div>
                  <div className="relative mt-2 flex items-center justify-center space-x-2 rounded-md">
                    {otp.map((data, index) => {
                      return (
                        <input
                          type="text"
                          maxLength={1}
                          className="block h-10 w-10 rounded-md border-gray-400 bg-white text-center text-lg font-semibold focus:border-olivine-500 focus:ring-olivine-500"
                          name="opt"
                          key={index}
                          value={data}
                          onChange={(e) => handleChange(e.target, index)}
                          onFocus={(e) => e.target.select()}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : null}
              <div className="mb-2 mt-6">
                <div className="flex justify-between text-center tracking-widest transition-all duration-300">
                  <button
                    onClick={() => {
                      onClose();
                    }}
                    className="rounded-md border-[1px] border-olivine-400 px-8 py-3 text-xs text-olivine-500 transition duration-300 hover:ring-1 hover:ring-olivine-600 sm:text-sm"
                  >
                    CANCEL
                  </button>
                  {!isLoading ? (
                    <button
                      disabled={
                        phone?.length === 11 &&
                        selectedAmount &&
                        otp?.join("")?.length === 4
                          ? false
                          : true
                      }
                      onClick={(e) => handleSubmit(e)}
                      className="rounded-md bg-olivine-500 px-8 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
                    >
                      BUY DATA
                    </button>
                  ) : (
                    <button
                      disabled={true}
                      className="cursor-not-allowed rounded-md bg-olivine-500 px-8 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
                    >
                      <IsLoadingFour />
                    </button>
                  )}
                </div>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}

export default BuyDataPopover;
