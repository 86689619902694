import React, { useContext, useEffect, useState } from "react";
import RxjsStoreContext from "../../../context/RxjsStoreContext";
import { useNavigate } from "react-router-dom";
import CurrencyFormat from "react-currency-format";

export default function BasketGiftFloatingPane(props) {
  const rxjsStore = useContext(RxjsStoreContext);
  let navigate = useNavigate();

  const [totalProductCount, setTotalProductCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  function viewMyBasket() {
    navigate(`/basket/custom-gift-my-basket`);
  }

  console.log("i am here...");

  useEffect(() => {
    rxjsStore.getUserSelectedBasket().subscribe((data) => {
      let tempTotalAmount = 0;
      let productSelected = new Map(data);

      setTotalProductCount(productSelected.size);
      for (let [key, product] of productSelected) {
        tempTotalAmount += Number(product?.rate) * product?.qty;
      }
      setTotalAmount(tempTotalAmount);
    });

    return () => {
      rxjsStore.saveBasketTemporarily();
    };
  }, []);

  return (
    <>
      {totalProductCount > 0 && (
        <section
          onClick={() => viewMyBasket()}
          className="xsm:max-w-[375px] border-olivine-500 bg-olivine-500 hover:bg-olivine-400 focus:ring-olivine-500 active:bg-olivine-600 fixed bottom-6 z-50 flex h-fit w-full max-w-[270px] transform cursor-pointer flex-row justify-center rounded-full border-[1px] px-4 py-3 text-sm font-semibold  text-white shadow shadow-gray-600 transition-all duration-200 hover:shadow-gray-400 focus:outline-none focus:ring-2 focus:ring-opacity-60 focus:ring-offset-2 sm:max-w-2xl sm:text-base"
        >
          <span className="mr-3"> Item(s): ({totalProductCount}) </span>
          <span className="">
            View Basket:
            <CurrencyFormat
              className={"pl-1 font-medium"}
              value={Number.parseFloat(totalAmount)?.toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₦"}
            />
          </span>
        </section>
      )}
    </>
  );
}