import React from "react";
import { Menu } from "@headlessui/react";
import Tooltip from "@mui/material/Tooltip";
import { FaCircleInfo } from "react-icons/fa6";

const TourDropdown = () => {
  return (
    <div className="relative">
      <Menu>
        {/* <Tooltip title="Add New" className="z-50"></Tooltip> */}
        <Menu.Button title="View Tour" className="h-8 w-8">
          <FaCircleInfo
            className="h-10 w-10 bg-white rounded-full flex-shrink-0 cursor-pointer"
            color="#558223"
          />
        </Menu.Button>

        <Menu.Items className="absolute bottom-12 z-50 right-0 flex w-[180px] flex-col rounded-md bg-white px-2 py-3 text-sm font-medium text-gray-600 shadow-md ring-[1px] ring-gray-200">
          <Menu.Item className="px-2 py-2 hover:bg-[#558223]/10">
            {({ active }) => (
              <a
                onClick={() => localStorage.setItem("isTour", true)}
                className={`${active && "bg-[#558223]/50"}`}
                href="/home/todo"
              >
                Home Tour
              </a>
            )}
          </Menu.Item>
          <Menu.Item className="px-2 py-2 hover:bg-[#558223]/10">
            {({ active }) => (
              <a
                onClick={() => localStorage.setItem("isTour_gropool", true)}
                className={`${active && "bg-[#558223]/50"}`}
                href="/gropool"
              >
                GroPool Tour
              </a>
            )}
          </Menu.Item>
          <Menu.Item className="px-2 py-2 hover:bg-[#558223]/10">
            {({ active }) => (
              <a
                onClick={() => localStorage.setItem("isTour_basket", true)}
                className={`${active && "bg-[#558223]/50"}`}
                href="/basket/create-basket"
              >
                Pick Your Basket Tour
              </a>
            )}
          </Menu.Item>
          {/* <Menu.Item className="px-2 py-2 hover:bg-[#558223]/10" disabled>
            <span className="opacity-75">Invite a friend (coming soon!)</span>
          </Menu.Item> */}
        </Menu.Items>
      </Menu>
    </div>
  );
};

export default TourDropdown;
