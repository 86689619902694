import React, {useState} from 'react'
import { FaMinus, FaPlus } from 'react-icons/fa'

const MonthSelectionComponent = () => {
    const [count, setCount]= useState(3)


  return (
    <div className='w-fit px-4 py-2 bg-[#E4E4E4] rounded-lg flex flex-row items-center space-x-2 ring-1 ring-gray-300'>
        <button onClick={() => setCount(prev => Math.max(0, prev - 1))}>
            <FaMinus className='h-3 w-3 text-gray-600'/>
        </button>
        <div className='text-gray-600 text-sm'>{count} {count < 2 ? "Month" : "Months"}</div>
        <button onClick={() => setCount(count + 1)}>
            <FaPlus className='h-3 w-3 text-gray-600' />
        </button>
    </div>
  )
}

export default MonthSelectionComponent