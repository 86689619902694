import React from 'react';
import SignedInNavbarImproved from '../../SignedInNavbarImproved';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { FiPlus } from 'react-icons/fi';
import MonthSelectionComponent from './MonthSelectionComponent';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { IoInformationCircleSharp } from 'react-icons/io5';
import { BiEditAlt } from 'react-icons/bi';
import Tooltip from "@mui/material/Tooltip";

const CheckoutPage = () => {
  return (
    <> 
        <SignedInNavbarImproved />
        <div className="mx-auto max-w-[1600px] bg-[#F5F5F5] lg:grid lg:grid-cols-9">    
            <div className="pb-20 lg:pb-40 px-2 xsm:px-4  sm:px-8 lg:px-14 lg:col-span-6">
                <h1 className="mt-14 text-4xl font-bold text-[#558223] tracking-wide">
                Checkout
                </h1>
                <div className="flex flex-col">
                    <div className="mt-8">
                        <div className='flex flex-row items-center justify-between'> 
                            <h2 className='text-2xl font-bold text-gray-700'>Plan</h2>
                            <div className='flex flex-row items-center space-x-2'>
                                <button className='px-2.5 py-2 rounded-full bg-[#E7EBE3] text-xs font-medium text-[#558223] flex items-center space-x-1 border-[1px] border-gray-200 hover:bg-[#E7EBE3]/60'>
                                    <FiPlus className='h-3.5 w-3.5' /> <span>Add another Item</span>
                                </button>
                                <button className='group h-8 w-8 rounded-full bg-[#F9D9D9] flex items-center justify-center flex-shrink-0 cursor-pointer hover:bg-[#F9D9D9]/50' >
                                    <RiDeleteBin5Fill className='h-4 w-4 text-[#E81414] group-hover:text-[#E81414]/80' />
                                </button>
                            </div>
                        </div>
                        <div className='mt-6 flex flex-row items-center justify-between'>
                            <div>
                                <h3 className='text-base font-semibold text-gray-600'>Bronze Basket</h3>
                                <p className='text-sm text-gray-500'>1 month subscription <span className='font-semibold'>120,000</span></p>
                            </div>
                            <div>
                                <MonthSelectionComponent/>
                            </div>
                        </div>
                        <div className='mt-5 flex flex-row items-center justify-between'>
                            <div>
                                <h3 className='text-base font-semibold text-gray-600'>Addons</h3>
                                <p className='text-sm text-gray-500'>1 month subscription <span className='font-semibold'>120,000</span></p>
                            </div>
                            <div>
                                <MonthSelectionComponent/>
                            </div>
                        </div>
                        <div className='mt-5 flex flex-row items-center justify-between'>
                            <div>
                                <h3 className='text-base font-semibold text-gray-600'>Addons</h3>
                                <p className='text-sm text-gray-500'>1 month subscription <span className='font-semibold'>120,000</span></p>
                            </div>
                            <div>
                                <MonthSelectionComponent/>
                            </div>
                        </div>
                        <div className='mt-5 flex flex-row items-center space-x-4'>
                            <button className='px-4 py-3 rounded-md bg-[#E7EBE3] text-xs font-medium text-[#558223] flex items-center space-x-1 border-[1.5px] border-[#558223] hover:bg-[#E7EBE3]/60 hover:border-[#558223]/70'>
                                <FiPlus className='h-3.5 w-3.5' /> <span>Add to this Order</span>
                            </button>
                            <button className='px-4 py-3 rounded-md bg-white text-xs font-medium text-gray-600 border-[1.5px] border-gray-500 border-dashed hover:border-gray-500/70'>
                            Duplicate this Order
                            </button>
                        </div>
                    </div>
                    <div className="mt-14">
                        <div className='flex flex-row items-center justify-between'> 
                            <h2 className='text-2xl font-bold text-gray-700'>Create your Basket</h2>
                            <div className='flex flex-row items-center space-x-2'>
                                <button className='px-2.5 py-2 rounded-full bg-[#E7EBE3] text-xs font-medium text-[#558223] flex items-center space-x-1 border-[1px] border-gray-200 hover:bg-[#E7EBE3]/60'>
                                    <FiPlus className='h-3.5 w-3.5' /> <span>Add another Item</span>
                                </button>
                                <button className='group h-8 w-8 rounded-full bg-[#F9D9D9] flex items-center justify-center flex-shrink-0 cursor-pointer hover:bg-[#F9D9D9]/50' >
                                    <RiDeleteBin5Fill className='h-4 w-4 text-[#E81414] group-hover:text-[#E81414]/80' />
                                </button>
                            </div>
                        </div>
                        <div className='mt-6 flex flex-row items-center justify-between'>
                            <div>
                                <h3 className='text-base font-semibold text-gray-600'>Bronze Basket</h3>
                                <p className='text-sm text-gray-500'>1 month subscription <span className='font-semibold'>120,000</span></p>
                            </div>
                            <div>
                                <MonthSelectionComponent/>
                            </div>
                        </div>
                        <div className='mt-5 flex flex-row items-center justify-between'>
                            <div>
                                <h3 className='text-base font-semibold text-gray-600'>Bronze Basket</h3>
                                <p className='text-sm text-gray-500'>1 month subscription <span className='font-semibold'>120,000</span></p>
                            </div>
                            <div>
                                <MonthSelectionComponent/>
                            </div>
                        </div>
                        <div className='mt-5 flex flex-row items-center space-x-4'>
                            <button className='px-4 py-3 rounded-md bg-[#E7EBE3] text-xs font-medium text-[#558223] flex items-center space-x-1 border-[1.5px] border-[#558223] hover:bg-[#E7EBE3]/60 hover:border-[#558223]/70'>
                                <FiPlus className='h-3.5 w-3.5' /> <span>Add to this Order</span>
                            </button>
                            <button className='px-4 py-3 rounded-md bg-white text-xs font-medium text-gray-600 border-[1.5px] border-gray-500 border-dashed hover:border-gray-500/70'>
                            Duplicate this Order
                            </button>
                        </div>
                    </div>
                    <div className="mt-14">
                        <div className='flex flex-row items-center justify-between'> 
                            <h2 className='text-2xl font-bold text-gray-700'>The Butchers</h2>
                            <div className='flex flex-row items-center space-x-2'>
                                <button className='px-2.5 py-2 rounded-full bg-[#E7EBE3] text-xs font-medium text-[#558223] flex items-center space-x-1 border-[1px] border-gray-200 hover:bg-[#E7EBE3]/60'>
                                    <FiPlus className='h-3.5 w-3.5' /> <span>Add another Item</span>
                                </button>
                                <button className='group h-8 w-8 rounded-full bg-[#F9D9D9] flex items-center justify-center flex-shrink-0 cursor-pointer hover:bg-[#F9D9D9]/50' >
                                    <RiDeleteBin5Fill className='h-4 w-4 text-[#E81414] group-hover:text-[#E81414]/80' />
                                </button>
                            </div>
                        </div>
                        <div className='mt-6 flex flex-row items-center justify-between'>
                            <div>
                                <h3 className='text-base font-semibold text-gray-600'>Bronze Basket</h3>
                                <p className='text-sm text-gray-500'>1 month subscription <span className='font-semibold'>120,000</span></p>
                            </div>
                            <div>
                                <MonthSelectionComponent/>
                            </div>
                        </div>
                        <div className='mt-5 flex flex-row items-center justify-between'>
                            <div>
                                <h3 className='text-base font-semibold text-gray-600'>Addons</h3>
                                <p className='text-sm text-gray-500'>1 month subscription <span className='font-semibold'>120,000</span></p>
                            </div>
                            <div>
                                <MonthSelectionComponent/>
                            </div>
                        </div>
                        <div className='mt-5 flex flex-row items-center space-x-4'>
                            <button className='px-4 py-3 rounded-md bg-[#E7EBE3] text-xs font-medium text-[#558223] flex items-center space-x-1 border-[1.5px] border-[#558223] hover:bg-[#E7EBE3]/60 hover:border-[#558223]/70'>
                                <FiPlus className='h-3.5 w-3.5' /> <span>Add to this Order</span>
                            </button>
                            <button className='px-4 py-3 rounded-md bg-white text-xs font-medium text-gray-600 border-[1.5px] border-gray-500 border-dashed hover:border-gray-500/70'>
                                Duplicate Item
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='px-2 xsm:px-4 sm:px-8 lg:px-0 w-full pb-40 bg-white lg:col-span-3 '>
                <div className='py-6 flex flex-row items-center space-x-2 lg:px-4'>
                    <div>
                        <svg width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.857178 5.67854C0.857178 4.39982 1.36515 3.17347 2.26934 2.26928C3.17353 1.36509 4.39988 0.857117 5.67861 0.857117H24.3215C25.6002 0.857117 26.8265 1.36509 27.7307 2.26928C28.6349 3.17347 29.1429 4.39982 29.1429 5.67854V16.2857H21.1072C19.8285 16.2857 18.6021 16.7937 17.6979 17.6979C16.7937 18.602 16.2857 19.8284 16.2857 21.1071V29.1428H5.67861C4.39988 29.1428 3.17353 28.6349 2.26934 27.7307C1.36515 26.8265 0.857178 25.6001 0.857178 24.3214V5.67854ZM18.2143 29.1428H18.2915L29.1429 18.2914V18.2143H21.1072C20.3399 18.2143 19.6041 18.519 19.0616 19.0616C18.5191 19.6041 18.2143 20.3399 18.2143 21.1071V29.1428Z" fill="#558223"/>
                        </svg>
                    </div>
                    <h4 className='text-base font-semibold text-gray-700'>Delivery Instructions</h4>
                </div>
                <div className='h-[1px] w-full bg-[#DFE5D1]'/>
                <div className="py-6 w-full bg-white lg:px-4">
                    <Disclosure>
                        {({ open }) => (
                            <>
                            <Disclosure.Button className="flex flex-row w-full items-center justify-between text-left">
                                <div className='flex flex-row items-center space-x-2'>
                                    <div>
                                        <svg width="27" height="24" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.59 0.39502C9.755 0.38002 6.995 3.11002 8.255 6.50002H3.5C2.70435 6.50002 1.94129 6.81609 1.37868 7.3787C0.816071 7.94131 0.5 8.70437 0.5 9.50002V12.5C0.5 12.8978 0.658035 13.2794 0.93934 13.5607C1.22064 13.842 1.60218 14 2 14H15.5V9.50002H18.5V14H32C32.3978 14 32.7794 13.842 33.0607 13.5607C33.342 13.2794 33.5 12.8978 33.5 12.5V9.50002C33.5 8.70437 33.1839 7.94131 32.6213 7.3787C32.0587 6.81609 31.2956 6.50002 30.5 6.50002H25.745C27.5 1.59502 20.9 -1.86998 17.855 2.36002L17 3.50002L16.145 2.33002C15.2 0.99502 13.895 0.41002 12.59 0.39502ZM12.5 3.50002C13.835 3.50002 14.51 5.12002 13.565 6.06502C12.62 7.01002 11 6.33502 11 5.00002C11 4.6022 11.158 4.22066 11.4393 3.93936C11.7206 3.65806 12.1022 3.50002 12.5 3.50002ZM21.5 3.50002C22.835 3.50002 23.51 5.12002 22.565 6.06502C21.62 7.01002 20 6.33502 20 5.00002C20 4.6022 20.158 4.22066 20.4393 3.93936C20.7206 3.65806 21.1022 3.50002 21.5 3.50002ZM2 15.5V27.5C2 28.2957 2.31607 29.0587 2.87868 29.6213C3.44129 30.184 4.20435 30.5 5 30.5H29C29.7956 30.5 30.5587 30.184 31.1213 29.6213C31.6839 29.0587 32 28.2957 32 27.5V15.5H18.5V27.5H15.5V15.5H2Z" fill="#558223"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <h4 className='text-base font-semibold text-gray-700'>Gift this Order</h4>
                                        <p className='text-xs text-gray-600'>All we need are the recipient’s Information</p>
                                    </div>
                                </div>
                                <ChevronUpIcon
                                className={`${
                                    open ? 'rotate-180 transform' : ''
                                } h-5 w-5 text-gray-600`}
                                />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm">
                                <div className='flex flex-row items-center gap-4 justify-between'>
                                    <h4 className='text-sm font-bold text-gray-700'>Gift Recipient Details</h4>
                                    <div className='flex flex-row items-center space-x-2'>
                                        <button className='group flex flex-row items-center space-x-1 px-2 py-1 rounded-full bg-[#558223]/20 hover:bg-[#558223]/15'>
                                            <BiEditAlt className='h-3 w-3 text-[#558223] group-hover:text-[#558223]/80' />
                                            <span className='text-xs font-medium text-[#558223] group-hover:text-[#558223]/80'>Edit</span>
                                        </button>
                                        <button className='group flex flex-row items-center space-x-1 px-2 py-1 rounded-full bg-[#E81414]/20 hover:bg-[#E81414]/15'>
                                            <RiDeleteBin5Fill className='h-3 w-3 text-[#E81414] group-hover:text-[#E81414]/80' />
                                            <span className='text-xs font-medium text-[#E81414] group-hover:text-[#E81414]/80'>Delete</span>
                                        </button>
                                    </div>
                                </div>
                                <div className='mt-4 text-sm text-gray-600 space-y-1'>
                                    <p>Name</p>
                                    <p>Mobile Number</p>
                                    <p>Email Address</p>
                                    <p>Full Address Details</p>
                                    <p>Full Address Details</p>
                                    <p>Full Address Details</p>
                                </div>
                            </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                </div>
                <div className='h-[1px] w-full bg-[#DFE5D1]'/>
                <div className="mt-10 lg:px-4">
                    <form>
                        <label className="ml-1 text-base font-semibold text-gray-700">
                            Coupon
                        </label>
                        <div className="mt-2 flex justify-between space-x-2">
                            <input
                            type="text"
                            placeholder="Input coupon code"
                            className="h-11 border-none flex-1 rounded-md ring-gray-300 bg-white text-sm placeholder:text-sm placeholder:text-gray-400 focus:outline-none focus:border-none ring-1  focus:ring-2 focus:ring-olivine-500"
                            />
                            <button className="rounded-md border-[1px] border-[#8DAA6A] bg-[#E7EBE3] px-2 py-2.5 text-xs font-medium text-olivine-500 hover:bg-olivine-100 xsm:px-4 xsm:text-sm">
                               Apply Code
                            </button>
                        </div>
                    </form>
                </div>
                <div className='mt-12 px-4'>
                    <div className='px-2 flex flex-row items-center justify-between text-gray-700'>
                        <p className=" text-mine-shaft-500 font-medium">Items Total (40)</p>
                        <p className='font-semibold text-base'>N 44,000</p>
                    </div>
                    <div className='mt-1 px-2 flex flex-row items-center justify-between text-gray-700'>
                        <p className='flex items-center space-x-3 text-mine-shaft-500 font-medium'>Delivery Fees 
                            <Tooltip title="Delivery Fees">
                                <span className='mt-[2px]'>  
                                    <IoInformationCircleSharp className='h-5 w-5 text-[#558223]'/>
                                </span>
                            </Tooltip>
                        </p>
                        <p className='font-semibold text-base'>N 2,000</p>
                    </div>
                    <div className='mt-1 px-2 flex flex-row items-center justify-between text-gray-700'>
                        <p className=' text-mine-shaft-500 font-medium'>Coupon</p>
                        <p className='text-[#E81414] font-semibold text-base'>-N 2,000</p>
                    </div>
                    <div className='mt-2 px-2 flex flex-row items-center justify-between text-xl font-bold text-gray-700'>
                        <p>Total</p>
                        <p>NGN 44,000</p>
                    </div>
                    <button className='mt-10 w-full py-3.5 text-sm font-medium bg-[#8DAA6A] text-white rounded-md hover:bg-[#8DAA6A]/80 active:bg-[#8DAA6A] active:ring-2 active:ring-[#8DAA6A] active:ring-offset-2 transition-all duration-400 ease-in-out'>
                       Proceed to Payment
                    </button>
                </div>
            </div>
        </div>
    </>
  )
}

export default CheckoutPage